import * as React from 'react'
import { Link } from 'gatsby'

const ContactUsBanner = ({ title, description, thankYouMsg }) => {
    return(
        <section className='in-view contact-us__banner'>
            <div className="contact-us__banner-content text-center slide-up d-flex justify-content-center align-items-center py-5">
                <div className="py-0 pt-lg-5 w-100">
                    {(title)?
                        <h2 className='text-center px-5' dangerouslySetInnerHTML={{ __html: title }} />
                    : null}
                    {(description)?
                        <div className='mx-auto desc-wrapper mt-lg-0 pt-3 mb-3 mb-lg-0' dangerouslySetInnerHTML={{ __html: description }}/>
                    : null}
                    {(thankYouMsg)? 
                        <Link to="/">
                            <button className='btn btn-primary-2 primary-2-modify mt-5' type='button'>
                                <span className='btn-hover'>Back to Home</span>
                            </button>
                        </Link>
                    : null}
                </div>
            </div>
        </section>
    )
}

export default ContactUsBanner