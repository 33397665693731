import React, { useState } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import { Seo } from '../components/Seo'

//form validation
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"

//axios
import axios from 'axios'

//components
import ContactUsBanner from '../components/ContactUsBanner'

//assets
import { HiChevronDown } from 'react-icons/hi'

//preview
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const ContactUsPage = ({ data }) => {
    const pageData = data.prismicContactUsPage.data
    const [thankYouMsg, setThankYouMsg] = useState(false)
    const [failedMsg, setFailedMsg] = useState(false)

    const submitDatalayer = (data) => {
        //Data layer - Contact form submitted
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(data)
    }
    return(
        <Layout currentPage={"contact-us"} customPage={true} thankYouPage={(thankYouMsg)? true : false}>
            <Seo
                title={(pageData.meta_title)? pageData.meta_title.text : null}
                description={(pageData.meta_description)? pageData.meta_description.text : null}
            />
            {(!thankYouMsg)?
            <>
                <ContactUsBanner
                    title={"Get in touch"}
                    description={"Feel free to ask us a question by sending us a message, <br/> we’re here to help you discover wines you love."}
                />
                    {/* Form */}
                    <Formik
                        initialValues = { //initial form values - empty
                            {
                                topic: "",
                                name: "",
                                email: "",
                                message: ""
                            }
                        }
                        validationSchema={ Yup.object({ //validate form
                            name: Yup.string().required("Required"),
                            email: Yup.string().email("Invalid email address").required("Required"),
                            topic: Yup.string().required("Required")
                        }) }
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(false)

                            //Send JSON data to API

                            let fullname = values.name.split(' ')
                            let firstname = fullname[0]
                            fullname.splice(0,1) //remove first name
                            let lastname = (fullname.length > 0)? fullname.join(' ') : ''

                            // if lastname is not defined set its value to
                            // "unknown" to address DB storedProcedure error.
                            if (!lastname) { lastname = "(unknown)" }

                            let details = []
                            details.push({KeyName: 'FullName', Value: values.name})

                            var data = {
                                ApplicationID: 1035, // API SERVICE
                                FirstName: firstname,
                                LastName: lastname,
                                Email: values.email,
                                Message: values.message,
                                InquiryTypeID: values.topic,
                                ExtraDetails: details
                            }

                            axios({
                                method: "post",
                                url: 'https://api.markanthony.com/ConsumerResponses/response',
                                data: JSON.stringify(data),
                                headers: {
                                    "Content-Type": "application/json; charset=utf-8"
                                }
                            }).then(function (response){
                                //handle success
                                console.log("Contact form is successfully submitted.")
                                var chosenTopic = "";
                                if(values.topic === "100000014"){
                                    chosenTopic = "Brands"
                                }else if(values.topic === "100000003"){
                                    chosenTopic = "Quality Concern"
                                }else if(values.topic === "100000001"){
                                    chosenTopic = "Product/Flavour"
                                }else if(values.topic === "100000002"){
                                    chosenTopic = "Ingredients/Nutrition Info"
                                }else if(values.topic === "100000007"){
                                    chosenTopic = "PR Opportunity"
                                }else if(values.topic === "100000005"){
                                    chosenTopic = "Sponsorship"
                                }else if(values.topic === "100000000"){
                                    chosenTopic = "Other"
                                }
                                //Data layer
                                let dl = {
                                    'event': 'contact_us_form_submitted',
                                    'topic': `${chosenTopic.toLowerCase()}`
                                }
                                submitDatalayer(dl)
                            }).catch(function(response){
                                //handle error
                                console.log("Contact form submission has failed.")
                                setFailedMsg(true)
                            })

                            //Reset form
                            resetForm()

                            //Update the form to Thank you message
                            setThankYouMsg(true)
                        }}
                    >
                        {({ values, errors, submitCount }) => {
                            return(
                                <Form className="contact-us__form mb-5 pb-5 in-view" autoComplete='off'>
                                    <div className='row py-2 slide-up'>
                                        <div className='col-12'>
                                            {/* Topics */}
                                            <div className={(values.topic && values.topic !== "-")? 'field-wrapper filled' : 'field-wrapper'}>
                                                <Field
                                                    as="select"
                                                    className="w-100"
                                                    id='topic'
                                                    name="topic"
                                                >
                                                    <option value="">Please select a topic</option>
                                                    <option value="100000014">Brands</option>
                                                    <option value="100000003">Quality Concern</option>
                                                    <option value="100000001">Product/Flavour</option>
                                                    <option value="100000002">Ingredients/Nutrition Info</option>
                                                    <option value="100000007">PR Opportunity</option>
                                                    <option value="100000005">Sponsorship</option>
                                                    <option value="100000000">Other</option>
                                                </Field>
                                                <label htmlFor="topic">What are you contacting us about today?</label>
                                                <HiChevronDown />
                                            </div>
                                            {((submitCount > 0) && errors.topic !== "")?
                                                <span className='error-msg'>{errors.topic}</span>
                                            : null}
                                        </div>
                                    </div>
                                    <div className='row py-2 slide-up'>
                                        <div className='col-6'>
                                            {/* Name */}
                                            <div className={(values.name)? 'field-wrapper filled' : 'field-wrapper'}>
                                                <Field type="text" name="name" className="w-100" id="name" />
                                                <label htmlFor="name">Your name</label>
                                            </div>
                                            {(submitCount > 0 && errors.name !== "")?
                                                <span className='error-msg'>{errors.name}</span>
                                            : null}
                                        </div>
                                        <div className='col-6'>
                                            {/* Email */}
                                            <div className={(values.email)? 'field-wrapper filled' : 'field-wrapper'}>
                                                <Field type="text" className="w-100" id="email" name="email" />
                                                <label htmlFor="email">Your email address</label>
                                            </div>
                                            {(submitCount > 0 && errors.email !== "")?
                                                <span className='error-msg'>{errors.email}</span>
                                            : null}
                                        </div>
                                    </div>
                                    <div className='row py-2 slide-up'>
                                        <div className='col-12'>
                                            {/* Message */}
                                            <div className={(values.message)? 'field-wrapper filled' : 'field-wrapper'}>
                                                <Field as="textarea" className="w-100" id="message" name="message" />
                                                <label htmlFor="message">Your message (optional)</label>
                                            </div>
                                            {(submitCount > 0 && errors.message !== "")?
                                                <span className='error-msg'>{errors.message}</span>
                                            : null}
                                        </div>
                                    </div>
                                    <div className='row py-2 text-center slide-up'>
                                        <div className='col-12'>
                                            <button className='btn btn-primary-2 primary-2-modify' type='submit'>
                                                <span className='btn-hover'>Send us your message</span>
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                    {/* Eof form */}
            </>
            :
            <>
                {(failedMsg)?
                // Failed
                <ContactUsBanner
                    title={"It seems that our mail <br/> server is not responding."}
                    description={"Please try again later!"}
                    thankYouMsg={true}
                />
                // Successfull
                : <ContactUsBanner
                    title={"Thank you"}
                    description={"We got your message and <br/> we'll respond as soon as we can."}
                    thankYouMsg={true}
                />
                }
            </>}
        </Layout>
    )
}

export default withPrismicPreview(ContactUsPage)

export const query = graphql`
  query ContactUsPageQuery {
    prismicContactUsPage {
        _previewable
        data {
            meta_description {
            text
            }
            meta_title {
            text
            }
        }
    }
  }
`

